// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import stylesheet from "~/tailwind.css";
import { json } from "@remix-run/node";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import FormWrapper from "./components/FormWrapper";
import FormInput from "./components/FormInput";
import FormCheckbox from "./components/FormCheckbox";
import FormTextarea from "./components/FormTextarea";
import Feature from "./components/Feature";
import Grid from "./components/Grid";
import Page from "./components/Page";
import Teaser from "./components/Teaser";
import Layout from "./components/Layout";
import Hero from "./components/Hero";
import Article from "./components/Article";
import AllArticles from "./components/AllArticles";
import PopularArticles from "./components/PopularArticles";
import { isPreview } from "~/utils/isPreview";
const components = {
  FormWrapper,
  FormInput,
  FormCheckbox,
  FormTextarea,
  feature: Feature,
  grid: Grid,
  teaser: Teaser,
  page: Page,
  hero: Hero,
  article: Article,
  "all-articles": AllArticles,
  "popular-articles": PopularArticles
};
const isServer = typeof window === "undefined";
const accessToken = isServer ? process.env.STORYBLOK_TOKEN : window.env.STORYBLOK_TOKEN;
storyblokInit({
  accessToken,
  use: [apiPlugin],
  components,
  bridge: isPreview()
});
export const loader = async ({
  params
}) => {
  let lang = params.lang || "default";
  return json({
    lang,
    env: {
      STORYBLOK_TOKEN: process.env.STORYBLOK_TOKEN,
      STORYBLOK_IS_PREVIEW: process.env.STORYBLOK_IS_PREVIEW
    }
  });
};
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export default function App() {
  _s();
  const {
    lang,
    env
  } = useLoaderData();
  return <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        
        <script dangerouslySetInnerHTML={{
        __html: `window.env = ${JSON.stringify(env)}`
      }} />

        <script src="//app.storyblok.com/f/storyblok-v2-latest.js" type="text/javascript">
        </script>
      </body>
    </html>;
}
_s(App, "SOxySXSK2GJhtJxXMGSR5wqaU5w=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;