// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/FormInput.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/FormInput.jsx");
  import.meta.hot.lastModified = "1721379226685.448";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const FormInput = ({
  blok
}) => {
  return <div className="form-group mb-6" {...storyblokEditable(blok)}>
    <input type={blok.type} name={blok.name} className="form-control m-0 block w-full rounded-[8px] border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-primary transition ease-in-out focus:border-primary focus:bg-white focus:text-primary focus:outline-none" id={blok.name} placeholder={`${blok.placeholder} ${blok.required ? '' : '(optional)'}`} required={blok.required} />
  </div>;
};
_c = FormInput;
export default FormInput;
var _c;
$RefreshReg$(_c, "FormInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;