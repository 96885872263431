// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ArticleTeaser.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ArticleTeaser.jsx");
  import.meta.hot.lastModified = "1721379226681.448";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import { getTransLink, useCurrentLanguage } from "../utils/langs";
const ArticleTeaser = ({
  article
}) => {
  _s();
  const {
    currentLanguage
  } = useCurrentLanguage();
  return <div className="column feature">
      <div className="p-6">
        {article.image && <img className="object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl" src={`${article.image.filename}/m/360x240`} alt={article.image.alt} />}
        <h2 className="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">
          {article.title}
        </h2>
        <div className="mx-auto text-base leading-relaxed text-gray-500 line-clamp-2">
          {article.teaser}
        </div>
        <div className="mt-4">
          <Link to={getTransLink(`/blog/${article.slug}`, currentLanguage)} prefetch="intent" className="inline-flex items-center mt-4 font-semibold text-blue-600 lg:mb-0 hover:text-neutral-600">
            {currentLanguage === "en" ? "Read More »" : "Leer más »"}
          </Link>
        </div>
      </div>
    </div>;
};
_s(ArticleTeaser, "iNSFOgKs/2GuC7LPZdjmYw5+3O8=", false, function () {
  return [useCurrentLanguage];
});
_c = ArticleTeaser;
export default ArticleTeaser;
var _c;
$RefreshReg$(_c, "ArticleTeaser");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;