// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/PopularArticles.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/PopularArticles.jsx");
  import.meta.hot.lastModified = "1721379226697.4478";
}
// REMIX HMR END

import ArticleTeaser from "./ArticleTeaser";
import { storyblokEditable } from "@storyblok/react";
const PopularArticles = ({
  blok
}) => {
  return <>
      <h2 className="text-3xl">{blok.headline}</h2>
      <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3   lg:px-24 md:px-16" {...storyblokEditable(blok)}>
        {blok.articles.map(article => {
        article.content.slug = article.slug;
        return <ArticleTeaser article={article.content} key={article.uuid} />;
      })}
      </div>
    </>;
};
_c = PopularArticles;
export default PopularArticles;
var _c;
$RefreshReg$(_c, "PopularArticles");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;