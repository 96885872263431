// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Feature.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Feature.jsx");
  import.meta.hot.lastModified = "1721379226681.448";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const Feature = ({
  blok
}) => {
  return <div {...storyblokEditable(blok)} className="w-full bg-[#f7f6fd] rounded-[5px] text-center overflow-hidden">
      {blok.image && blok.image.filename && <img src={blok.image.filename} alt={blok.image.alt} className="w-full h-48 xl:h-72 object-cover" />}
      <div className="px-12 py-6">
        <h3 className="text-2xl text-[#1d243d] font-bold">{blok.name}</h3>
      </div>
    </div>;
};
_c = Feature;
export default Feature;
var _c;
$RefreshReg$(_c, "Feature");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;