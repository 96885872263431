// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/AllArticles.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/AllArticles.jsx");
  import.meta.hot.lastModified = "1721379226681.448";
}
// REMIX HMR END

import { useLoaderData } from "@remix-run/react";
import ArticleTeaser from "./ArticleTeaser";
import { storyblokEditable } from "@storyblok/react";
const AllArticles = ({
  blok
}) => {
  _s();
  const {
    articles
  } = useLoaderData();
  return <>
      <p className="text-3xl">{blok.title}</p>
      <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3   lg:px-24 md:px-16" {...storyblokEditable(blok)}>
        {articles?.length && articles.map(article => {
        article.content.slug = article.slug;
        return <ArticleTeaser article={article.content} key={article.uuid} />;
      })}
      </div>
    </>;
};
_s(AllArticles, "sDwwxKYY3LismUuPJgHuwOfiGxo=", false, function () {
  return [useLoaderData];
});
_c = AllArticles;
export default AllArticles;
var _c;
$RefreshReg$(_c, "AllArticles");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;