// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Page.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Page.jsx");
  import.meta.hot.lastModified = "1721381470054.6682";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const Page = ({
  blok
}) => <main {...storyblokEditable(blok)} key={blok._uid} className="px-4">

    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="container mx-auto flex justify-between items-center p-6">
          <img src="path-to-your-image/logo.png" alt="Finexus Logo" className="h-8" />
          <nav className="space-x-4 hidden md:block">
            <a href="#about" className="text-gray-700 hover:text-gray-900">About us</a>
            <a href="#lhdn" className="text-gray-700 hover:text-gray-900">LHDN e-Invoicing</a>
            <a href="#solutions" className="text-gray-700 hover:text-gray-900">Solutions</a>
            <a href="#career" className="text-gray-700 hover:text-gray-900">Career</a>
            <a href="#contact" className="text-gray-700 hover:text-gray-900">Contact</a>
            <a href="#faq" className="text-gray-700 hover:text-gray-900">FAQ</a>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl font-bold text-blue-900 mb-4">Bridging Borders with Innovative Fintech Solutions</h1>
            <p className="text-gray-700 mb-6">
              We are a diversified technology company providing SaaS solutions on the Finexus Cloud. Our cloud solutions include LHDN e-Invoicing, Regulatory Payments, and Regulatory Reporting for the Inland Revenue Board Malaysia (IRBM), Bank Negara Malaysia (BNM), and PayNet.
              We also a Data Processor for major card brands including Mastercard, Alipay, and Visa.
            </p>
            <div className="flex space-x-4">
              <a href="#learn-more" className="bg-yellow-500 text-white px-6 py-3 rounded shadow hover:bg-yellow-600">Learn More</a>
              <a href="#contact" className="text-blue-600 hover:underline">Get in touch</a>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="bg-gray-200 h-64 rounded-lg shadow-inner"></div>
          </div>
        </div>
      </section>

      {/* Statistics Section */}
      <section className="bg-blue-900 text-white py-12">
        <div className="container mx-auto grid grid-cols-2 md:grid-cols-5 gap-8 text-center">
          <div>
            <h2 className="text-3xl font-bold">Since</h2>
            <p className="text-5xl">2000</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">Clients</h2>
            <p className="text-5xl">90+</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">Compliance</h2>
            <p className="text-5xl">100%</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">Data Center</h2>
            <p className="text-5xl">24/7</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">Users</h2>
            <p className="text-5xl">600+</p>
          </div>
        </div>
      </section>

      {/* Highlights Section */}
      <section className="py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Highlight Card */}
            <div className="bg-white p-6 rounded-lg shadow">
              <img src="path-to-your-image/highlight1.png" alt="Highlight 1" className="w-full h-40 object-cover rounded mb-4" />
              <h3 className="text-xl font-bold mb-2">Finexus joined NEXEA's Disruptivent Summit Exhibition 2024</h3>
              <p className="text-gray-700 mb-4">19th May, 2024</p>
              <p className="text-gray-600">Finexus had a great time today at NEXEA's Disruptivent Summit Exhibition at Nexus, Kuala Lumpur.</p>
              <a href="#more" className="text-blue-600 hover:underline">More</a>
            </div>
            {/* Repeat similar highlight cards for other highlights */}
            <div className="bg-white p-6 rounded-lg shadow">
              <img src="path-to-your-image/highlight2.png" alt="Highlight 2" className="w-full h-40 object-cover rounded mb-4" />
              <h3 className="text-xl font-bold mb-2">Finexus Collaborating with DSAM for CEO Raya Luncheon at Saujana Hotel, Kuala Lumpur</h3>
              <p className="text-gray-700 mb-4">29th April, 2024</p>
              <p className="text-gray-600">Finexus and Malaysia Digital Economy Corporation (MDEC) representatives attended an event for CEO Raya Luncheon, hosted in collaboration with DSAM at The Saujana Hotel Kuala Lumpur.</p>
              <a href="#more" className="text-blue-600 hover:underline">More</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <img src="path-to-your-image/highlight3.png" alt="Highlight 3" className="w-full h-40 object-cover rounded mb-4" />
              <h3 className="text-xl font-bold mb-2">Finexus Hosted Singapore Polytechnic at Finexus</h3>
              <p className="text-gray-700 mb-4">28th March, 2024</p>
              <p className="text-gray-600">It was an absolute pleasure hosting Singapore Polytechnic at Finexus. It was an insightful and great fruitful discussion meeting.</p>
              <a href="#more" className="text-blue-600 hover:underline">More</a>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">Regulatory Reporting</h3>
              <p className="text-gray-600 mb-4">We help banks comply with IRBM reporting requirements.</p>
              <a href="#learn-more" className="text-blue-600 hover:underline">Learn more</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">Regulatory Payment</h3>
              <p className="text-gray-600 mb-4">We provide solutions to help banks comply with PayNet and IRBM reporting requirements.</p>
              <a href="#learn-more" className="text-blue-600 hover:underline">Learn more</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">Digital Money</h3>
              <p className="text-gray-600 mb-4">We offer global prepaid Visa and Mastercard wallets and accept payments from Visa, Mastercard, Alipay, and Alipay Plus.</p>
              <a href="#learn-more" className="text-blue-600 hover:underline">Learn more</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">Data Processor</h3>
              <p className="text-gray-600 mb-4">We offer Data Processor Services for 3D Secure Acquiring and Issuing Systems and PCI DSS-Certified Payment Gateway Services.</p>
              <a href="#learn-more" className="text-blue-600 hover:underline">Learn more</a>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16">
        <div className="container mx-auto text-center">
          <blockquote className="bg-blue-100 p-6 rounded-lg shadow inline-block max-w-4xl">
            <p className="text-lg text-gray-800 italic">"We are thrilled with the MyTouristPay app. Its user-friendly interface, seamless payment, and strong security have improved our services. Customers love its convenience and reliability, and our transactions have increased. Finexus professionalism made this project a success."</p>
            <footer className="mt-4">
              <p className="font-bold text-blue-900">Farhan Ahmad</p>
              <p className="text-gray-700">CEO, PayNet Sdn Bhd</p>
            </footer>
          </blockquote>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h3 className="font-bold mb-4">Who We Are</h3>
            <ul className="space-y-2">
              <li><a href="#about" className="hover:underline">About Us</a></li>
              <li><a href="#legal" className="hover:underline">Legal</a></li>
              <li><a href="#affiliates" className="hover:underline">Affiliates</a></li>
              <li><a href="#careers" className="hover:underline">Careers</a></li>
              <li><a href="#contact" className="hover:underline">Contact</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4">ARMA Platform</h3>
            <ul className="space-y-2">
              <li><a href="#lhdn" className="hover:underline">LHDN e-Invoicing</a></li>
              <li><a href="#sandbox" className="hover:underline">Sandbox</a></li>
              <li><a href="#api" className="hover:underline">API</a></li>
              <li><a href="#demo" className="hover:underline">Demo</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4">Solutions</h3>
            <ul className="space-y-2">
              <li><a href="#payment" className="hover:underline">Payment Gateway</a></li>
              <li><a href="#ecommerce" className="hover:underline">Ecommerce</a></li>
              <li><a href="#pos" className="hover:underline">POS System</a></li>
              <li><a href="#banking" className="hover:underline">Banking System</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4">Enterprises</h3>
            <ul className="space-y-2">
              <li><a href="#prepaid" className="hover:underline">Prepaid Card Wallet</a></li>
              <li><a href="#whitelabel" className="hover:underline">White-labeled Card Wallet</a></li>
              <li><a href="#remittance" className="hover:underline">Remittance</a></li>
              <li><a href="#switch" className="hover:underline">Switch Solutions</a></li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-8">
          <p>&copy; 2024 FINEXUS. All rights reserved.</p>
        </div>
      </footer>
    </div>


  </main>;
_c = Page;
export default Page;
var _c;
$RefreshReg$(_c, "Page");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;